import KeenSliderHelper from '~/classes/keen-slider-helper'

window.addEventListener('DOMContentLoaded', () => {
    initActiveSlider()
    initListeners()
})

const initListeners = () => {
    document.addEventListener('click', (e: Event) => {
        const target = e.target as HTMLElement

        if (!target.classList.contains('tabs-social-block__link')) return void 0

        const selectedTabId = Number(target.dataset.id)
        const tabsLinksList = document.querySelectorAll<HTMLElement>('.tabs-social-block__link')

        if (!tabsLinksList.length) return void 0

        tabsLinksList.forEach((tabsLinkItem: HTMLElement) => {
            tabsLinkItem.classList.remove('tabs-social-block__link--selected')

            if (Number(tabsLinkItem.dataset.id) === selectedTabId) tabsLinkItem.classList.add('tabs-social-block__link--selected')

            const oldSelectElement = document.querySelector<HTMLElement>('.tabs-social-block__groups-item--selected')
            const newSelectElement = document.querySelector<HTMLElement>(`.tabs-social-block__groups-item[data-id='${selectedTabId}']`)

            if (!oldSelectElement || !newSelectElement) return void 0

            oldSelectElement.classList.remove("tabs-social-block__groups-item--selected")
            newSelectElement.classList.add("tabs-social-block__groups-item--selected")

            initActiveSlider()
        })
    })
}

const initActiveSlider = () => {
    const activeSliderContainer = document.querySelector<HTMLElement>('.tabs-social-block__groups-item--selected')
    const activeSliderElement = activeSliderContainer?.querySelector<HTMLElement>('.tabs-social-block__list')
    const parentContainer = activeSliderElement?.parentElement
    const sliderDotsListElement = parentContainer?.querySelector<HTMLElement>('.keen-slider-wrapper__dots-list')

    if (!activeSliderElement || sliderDotsListElement) return void 0

    const sliderHelperInstance = new KeenSliderHelper(
        activeSliderElement,
        {
            slides: {
                perView: 1,
                spacing: 40,
            },
            defaultAnimation: {
                duration: 50,
            },
            breakpoints: {
                '(min-width: 640px)': {
                    slides: {
                        perView: 2,
                        spacing: 24
                    }
                },
                '(min-width: 960px)': {
                    slides: {
                        perView: 3,
                        spacing: 24
                    }
                },
                '(min-width: 1200px)': {
                    slides: {
                        perView: 3,
                        spacing: 40
                    }
                },
                '(min-width: 1440px)': {
                    slides: {
                        perView: 4,
                        spacing: 40
                    }
                },
            }
        }
    )
    sliderHelperInstance.createDotsMarkup(false)
    activeSliderElement.classList.remove('opacity-0')
}
