import bem from "bem-ts"
import { KeenSliderInstance } from 'keen-slider'
import KeenSliderHelper from '~/classes/keen-slider-helper'

const b = bem("index-info", {strict: false})

let textSlider: KeenSliderInstance | null = null

window.addEventListener('DOMContentLoaded', () => {
    const infoElement = document.querySelector('.' + b()) as HTMLElement

    if (!infoElement) return void 0

    initShowMoreEvent()
    initBuyTextInfoSlider()
    initNumbersInfoSlider()
    initAdvTextInfoSlider()
})

const initShowMoreEvent = () => {
    const button = document.querySelector('.' +  b('show-details')) as HTMLElement

    if (button) button.addEventListener('click', toggleInfoBlock)
}

const toggleInfoBlock = () => {
    const element = event?.target as HTMLElement

    if (!element) return void 0

    element.classList.toggle(b('show-details') + '--toggled')

    const block = document.querySelector('.' +  b('details')) as HTMLElement

    if (block) block.classList.toggle(b('details') + '--shown')

    if (element.classList.contains(b('show-details') + '--toggled') && textSlider) textSlider.update()
}

const initAdvTextInfoSlider = () => {
    const container = document.getElementById('index-info-adv-text-slider') as HTMLElement

    if (!container) return void 0

    const slider = new KeenSliderHelper(
        container,
        {
            slides: {
                perView: 1,
                spacing: 0,
            },
            breakpoints: {
                '(min-width: 640px)': {
                    slides: {
                        perView: 2,
                        spacing: 24
                    }
                },
                '(min-width: 960px)': {
                    slides: {
                        perView: 3,
                        spacing: 24
                    }
                },
                '(min-width: 1200px)': {
                    slides: {
                        perView: 3,
                        spacing: 40
                    }
                },
                '(min-width: 1920px)': {
                    slides: {
                        perView: 4,
                        spacing: 40
                    }
                },
            }
        }
    )

    slider.createAdditionalMarkup({
        arrows: {
            enable: false,
        },
        dots: {
            enable: true
        },
        counter: {
            enable: false
        }
    })

    container.classList.remove('opacity-0')
}

const initBuyTextInfoSlider = () => {
    const container = document.getElementById('index-info-buy-from-us-slider') as HTMLElement

    if (!container) return void 0

    const slider = new KeenSliderHelper(
        container,
        {
            slides: {
                perView: 1,
                spacing: 0,
            },
        }
    )

    slider.createAdditionalMarkup({
        arrows: {
            enable: true,
        },
        dots: {
            enable: false
        },
        counter: {
            enable: true
        }
    })

    container.classList.remove('opacity-0')

    textSlider = slider.instance
}

const initNumbersInfoSlider = () => {
    const container = document.getElementById('index-info-throw-me-some-numbers-slider') as HTMLElement

    if (!container) return void 0

    const slider = new KeenSliderHelper(
        container,
        {
            slides: {
                perView: 1,
                spacing: 0,
            },
            breakpoints: {
                '(min-width: 640px)': {
                    slides: {
                        perView: 2,
                        spacing: 24
                    }
                },
                '(min-width: 960px)': {
                    slides: {
                        perView: 3,
                        spacing: 24
                    }
                },
            }
        }
    )

    slider.createAdditionalMarkup({
        arrows: {
            enable: false,
        },
        dots: {
            enable: true
        },
        counter: {
            enable: false
        }
    })

    container.classList.remove('opacity-0')
}
